import React from 'react';
import './Services.css'; // External CSS for styling
const Services = () => {
  return (
    <div className="services-container">
   <p>404 not found</p>
    </div>
  );
};

export default Services;
